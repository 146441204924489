import React from 'react';
import Radium from 'radium';
import { graphql, Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import get from 'lodash/get';
import { LightSpeed, Fade } from 'react-reveal';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FadeSlider from '../components/fade-slider';
import Button from '../components/button';
import Footer from '../components/footer';
import { isAndAbove, isAndBelow } from '../util';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const ServicesIndex = props => {
  const slides = get(props, 'data.allContentfulStudioPageSlide.nodes');
  const featuredEquipment = get(props, 'data.allContentfulFeaturedEquipment.nodes');
  const equipmentRecording = get(props, 'data.contentfulEquipmentRecording');
  const equipmentMonitoring = get(props, 'data.contentfulEquipmentMonitoring');
  const equipmentPlugin = get(props, 'data.contentfulEquipmentPlugin');
  const equipmentMicrophone = get(props, 'data.contentfulEquipmentMicrophone');
  const equipmentOutboard = get(props, 'data.contentfulEquipmentOutboard');
  const equipmentEffectAndAmplifier = get(props, 'data.contentfulEquipmentEffectAndAmplifier');
  const equipmentInstrument = get(props, 'data.contentfulEquipmentInstrument');
  const services = get(props, 'data.allContentfulService.nodes');

  const breakpoints = useBreakpoint();

  const isEven = number => {
    return number % 2 === 0;
  };

  const equipmentListRenderOptions = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => <ul className="list-none">{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className="my-1">{children}</li>
    }
  };

  return (
    <Layout location={props.location} breakpoints={breakpoints}>
      <SEO title="Services" />
      <FadeSlider slides={slides} height={isAndAbove('md', breakpoints) ? 750 : 400} />
      <div id="studio" className="bg-gray-900">
        <div className="m-auto px-6 text-white" style={{ maxWidth: 1280 }}>
          <div
            className="rounded bg-blue mx-auto text-center max-w-3xl p-4 md:p-8 text-2xl sm:text-4xl leading-8 font-extrabold"
            style={{ transform: 'translateY(-50%)' }}>
            <LightSpeed left cascade>
              WELCOME TO THE STUDIO
            </LightSpeed>
          </div>
          <div className="mt-4 text-center text-lg sm:text-xl leading-relaxed">
            <Fade delay={500}>
              With almost 20 years of recording and producing experience, one of our biggest joys at Zeo Music is to
              help artists at any level create and cultivate what they’ve envisioned for their music and artistry.
              Located in the heart of Honolulu, Hawai‘i, Zeo Music’s recording studio is capable of capturing the most
              ideal version of your music.
            </Fade>
          </div>
          <div className="mx-auto my-8 max-w-4xl text-center text-xl sm:text-3xl text-blue-lighter font-extrabold tracking-wide">
            <Fade delay={1000}>
              RECORDING • ENGINEERING • MIXING • PRODUCTION SESSION MUSICIAN • EDITING • VOICE OVER • SOUND DESIGN
            </Fade>
          </div>
          <div
            className="rounded bg-blue mx-auto text-center max-w-lg p-4 text-2xl sm:text-3xl leading-8 font-extrabold"
            style={{ transform: 'translateY(50%)' }}>
            EQUIPMENT
          </div>
        </div>
      </div>
      <div className="m-auto py-24 px-6" style={{ maxWidth: 1280 }}>
        <div className="grid grid-cols-2 gap-4 md:grid-cols-4 sm:gap-8 lg:grid-cols-6 lg:gap-8">
          {featuredEquipment.map((item, index) => (
            <Fade key={index} delay={500} duration={2000}>
              <img src={item.image.file.url} alt={item.image.title} />
            </Fade>
          ))}
        </div>
      </div>
      <div id="equipment" className="bg-gray-900">
        <div className="m-auto py-24 px-6" style={{ maxWidth: 1280 }}>
          <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-12 leading-relaxed">
            <div>
              <div className="mb-8 text-lg text-gray-400">
                <div className="text-2xl text-blue-lighter font-bold uppercase tracking-widest">Recording</div>
                {documentToReactComponents(equipmentRecording.list.json, equipmentListRenderOptions)}
              </div>
              <div className="mb-8 text-lg text-gray-400">
                <div className="text-2xl text-blue-lighter font-bold uppercase tracking-widest">Monitoring</div>
                {documentToReactComponents(equipmentMonitoring.list.json, equipmentListRenderOptions)}
              </div>
              <div className="mb-8 text-lg text-gray-400">
                <div className="text-2xl text-blue-lighter font-bold uppercase tracking-widest">Plug-Ins</div>
                {documentToReactComponents(equipmentPlugin.list.json, equipmentListRenderOptions)}
                <Link to={'/plugins'}>
                  <Button className="mt-4" text="VIEW FULL PLUG-IN LIST" size="sm" />
                </Link>
              </div>
            </div>
            <div>
              <div className="mb-8 text-lg text-gray-400">
                <div className="text-2xl text-blue-lighter font-bold uppercase tracking-widest">Microphones</div>
                {documentToReactComponents(equipmentMicrophone.list.json, equipmentListRenderOptions)}
              </div>
              <div className="mb-8 text-lg text-gray-400">
                <div className="text-2xl text-blue-lighter font-bold uppercase tracking-widest">Outboard</div>
                {documentToReactComponents(equipmentOutboard.list.json, equipmentListRenderOptions)}
              </div>
              <div className="mb-8 text-lg text-gray-400">
                <div className="text-2xl text-blue-lighter font-bold uppercase tracking-widest">
                  Effects & Amplifiers
                </div>
                {documentToReactComponents(equipmentEffectAndAmplifier.list.json, equipmentListRenderOptions)}
                <Link to={'/effects-and-amplifiers'}>
                  <Button className="mt-4" text="VIEW FULL EFFECT AND AMPLIFIER LIST" size="sm" />
                </Link>
              </div>
            </div>
            <div>
              <div className="mb-8 text-lg text-gray-400">
                <div className="text-2xl text-blue-lighter font-bold uppercase tracking-widest">Instruments</div>
                {documentToReactComponents(equipmentInstrument.list.json, equipmentListRenderOptions)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-auto pb-12 px-6" style={{ maxWidth: 1280 }}>
        <div
          className="rounded bg-blue mx-auto text-center max-w-lg p-4 text-2xl sm:text-3xl leading-8 text-white font-extrabold"
          style={{ transform: 'translateY(-50%)' }}>
          SERVICES
        </div>
        {services.map((service, index) => {
          return isEven(index) ? (
            <div className="my-12 grid grid-cols-1 md:grid-cols-2 md:gap-8">
              <Fade bottom>
                <img
                  className="rounded-xl"
                  style={{ height: isAndAbove('md', breakpoints) ? 400 : 300, width: '100%', objectFit: 'cover' }}
                  src={service.imageOnServicesPage.file.url}
                  alt={service.imageOnServicesPage.title}
                />
              </Fade>
              <Fade bottom>
                <div className="flex flex-col justify-center items-center text-center p-6 lg:p-12">
                  <p className="mt-2 text-3xl sm:text-4xl leading-8 font-extrabold tracking-tight text-blue uppercase">
                    {service.title}
                  </p>
                  <p className="mt-4 text-lg lg:text-xl">{service.descriptionOnServicesPage}</p>
                  <a href={service.buttonPath}>
                    <Button className="mt-4 uppercase" text={service.buttonTitle} />
                  </a>
                </div>
              </Fade>
            </div>
          ) : (
            <div className="my-12 grid grid-cols-1 md:grid-cols-2 md:gap-8">
              {isAndBelow('sm', breakpoints) && (
                <Fade bottom>
                  <img
                    className="rounded-xl"
                    style={{ height: isAndAbove('md', breakpoints) ? 400 : 300, width: '100%', objectFit: 'cover' }}
                    src={service.imageOnServicesPage.file.url}
                    alt={service.imageOnServicesPage.title}
                  />
                </Fade>
              )}
              <Fade bottom>
                <div className="flex flex-col justify-center items-center text-center p-6 lg:p-12">
                  <p className="mt-2 text-3xl sm:text-4xl leading-8 font-extrabold tracking-tight text-blue uppercase">
                    {' '}
                    {service.title}
                  </p>
                  <p className="mt-4 text-lg lg:text-xl">{service.descriptionOnServicesPage}</p>
                  <a href={service.buttonPath}>
                    <Button className="mt-4 uppercase" text={service.buttonTitle} />
                  </a>
                </div>
              </Fade>
              {isAndAbove('md', breakpoints) && (
                <Fade bottom>
                  <img
                    className="rounded-xl"
                    style={{ height: isAndAbove('md', breakpoints) ? 400 : 300, width: '100%', objectFit: 'cover' }}
                    src={service.imageOnServicesPage.file.url}
                    alt={service.imageOnServicesPage.title}
                  />
                </Fade>
              )}
            </div>
          );
        })}
      </div>
      <Footer breakpoints={breakpoints} />
    </Layout>
  );
};

export default Radium(ServicesIndex);

export const pageQuery = graphql`
  query ServicesIndexQuery {
    allContentfulStudioPageSlide(sort: { fields: updatedAt, order: DESC }) {
      nodes {
        image {
          file {
            url
          }
        }
      }
    }
    allContentfulFeaturedEquipment(sort: { fields: order }) {
      nodes {
        image {
          title
          file {
            url
          }
        }
      }
    }
    contentfulEquipmentRecording {
      list {
        json
      }
    }
    contentfulEquipmentMonitoring {
      list {
        json
      }
    }
    contentfulEquipmentPlugin {
      list {
        json
      }
    }
    contentfulEquipmentMicrophone {
      list {
        json
      }
    }
    contentfulEquipmentOutboard {
      list {
        json
      }
    }
    contentfulEquipmentEffectAndAmplifier {
      list {
        json
      }
    }
    contentfulEquipmentInstrument {
      list {
        json
      }
    }
    allContentfulService(sort: { fields: order }) {
      nodes {
        title
        descriptionOnServicesPage
        imageOnServicesPage {
          title
          file {
            url
          }
        }
        buttonTitle
        buttonPath
      }
    }
  }
`;
